.layout {
    background: #1E2325;
    color: #fff;
    width: 100%;
    min-height: 100%;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 9999;
}


.content {
    max-height: 100%;
    padding-top: 80px;
    overflow: auto;
    display: flex;
    justify-content: center;
}

@media (max-width: 750px) {
    .header {
        position: relative;
    }

    .content {
        padding-top: 20px;
    }
}