@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.fighting-game-intro-page {
    height: 100%;
    width: 100%;
    background: #0f0;
    padding: 20px;
}

.fighting-game-intro-content {
    height: 1080px;
    width: 1920px;
    background: #ccc;
    position: relative;
    overflow: hidden;
}

.fighting-game-intro-controls {
    margin-top: 20px;
}

.fighting-game-intro-background-image {
    height: 100%;
    width: 100%;
}

.fighting-game-intro-background-layer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.fighting-game-intro-select-portrait {
    position: absolute;
    z-index: 1;
    width: 65px;
    display: block;
    height: 80px;
    object-fit: contain;
    object-position: bottom;
}

.flag-portrait {
    position: absolute;
    opacity: 0.5;
    width: 500px;
    mix-blend-mode: overlay;
}

.flag-portrait.player1 {
    top: 60px;
    left: 190px;
}

.flag-portrait.player2 {
    top: 60px;
    left: 1220px;
    transform: scaleX(-1);
}

.flag-player-name {
    position: absolute;
    color: #EC376D;
    font-size: 200px;
    text-align: center;
    transform: translateX(-50%);
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: italic;
    opacity: 0.5;
    letter-spacing: 5px;
    text-shadow: 0px 0px 4px #fff;
}

@keyframes p1NameAnimation {
    from {
        left: -100px;
    }
    to {
        left: 420px;
    }
}

@keyframes p2NameAnimation {
    from {
        left: 1560px;
    }
    to {
        left: 1410px;
    }
}

.flag-player-name.player1 {
    top: 320px;
    left: 420px;
    animation-name: p1NameAnimation;
    animation-duration: 1s;
}

.flag-player-name.player2 {
    top: 320px;
    left: 1410px;
    animation-name: p2NameAnimation;
    animation-duration: 1s;
}

.main-portrait {
    position: absolute;
    bottom: 0;
    width: 775px;
}

@keyframes p1PortraitAnimation {
    from {
        left: -100px;
        opacity: 0;
    }
    to {
        left: 20px;
        opacity: 1;
    }
}

@keyframes p2PortraitAnimation {
    from {
        right: -100px;
        opacity: 0;
    }
    to {
        right: 20px;
        opacity: 1;
    }
}

.main-portrait.player1 {
    left: 20px;
    animation-name: p1PortraitAnimation;
    animation-duration: 1.5s;
}

.main-portrait.flipped {
    transform: scaleX(-1);
}

.main-portrait.player2 {
    right: 20px;
    animation-name: p2PortraitAnimation;
    animation-duration: 1.5s;
}

.player-select {
    position: absolute;
    z-index: 2;
    width: 65px;
    opacity: 0.5;
    transition: all 1s ease;
}

.go-button {
    font-size: 30px;
    margin-top: 10px;
}