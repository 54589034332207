.section-header {
    padding: 5px;
    background: #B823FF;
    font-size: 56px;
    font-weight: bold;
    flex-shrink: 0;
}

.section-header-text {
    background: #272536;
    color: #fff;
    width: calc(100% - 70px);
    text-transform: uppercase;
}

.section-header.small .section-header-text {
    width: calc(100% - 30px);
}

@media (max-width: 1000px) {
    .section-header {
        font-size: 48px;
    }
}