.header {
    height: 60px;
    background: #284399;
    color: #fff;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
}

.icon {
    height: 100%;
}

.icon img {
    display: block;
    height: 100%;

}

.nav-items {
    line-height: 40px;
    font-size: 36px;
    display: flex;
}

.nav-items * {
    margin: 0 10px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.nav-items a {
    cursor: pointer;
}

.nav-items a:hover {
    color: #1E2325;
}

.nav-items .active {
    text-decoration: underline;
}

.hamburger-button,
.x-button,
.nav-logo {
    display: none;
}

@media (max-width: 900px) {
    .nav-items {
        font-size: 28px;
    }
}

@media (max-width: 750px) {
    .icon {
        display: none;
    }

    .nav-items span {
        display: none;
    }

    .nav-items {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        transform: translateX(-101%);
        padding: 20px;
        flex-direction: column;
        font-size: 48px;
        line-height: initial;
        background: #284399;
        transition: all 300ms ease;
    }

    .nav-items * {
        margin: 0 0 10px;
    }

    .nav-items.open {
        transform: translateX(0);
    }

    .hamburger-button {
        display: block;
        border: none;
        background: transparent;
        fill: #fff;
        position: absolute;
    }

    .x-button {
        display: block;
        border: none;
        background: transparent;
        fill: #fff;
        width: 50px;
        height: 50px;
        margin: 0;
    }

    .x-button:hover {
        fill: #1E2325;
    }

    .nav-logo {
        display: block;
        height: 100%;
        flex-grow: 1;
    }

    .nav-logo > img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: contain;

    }
}

