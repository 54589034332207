.ending-logo {
    margin-top: 60px;
    margin-bottom: 60px;
    text-align: center;
}

.ending-logo > img {
    width: 400px;
}

@media (max-width: 500px) {
    .ending-logo > img {
        width: 200px;
    }
}