.mobile-header {
    background: linear-gradient(180deg, #6622CC 0%, #B823FF 100%);
    display: flex;
    padding: 30px;
    padding-top: 80px;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    height: 150px;
    align-items: center;
}

.hamburger-button {
    background: transparent;
    padding: 0;
    border: none;
    margin: 0 40px 0 0;
}

.hamburger-button svg{
    fill: #fff;
    transition: all 300ms ease;
}

.hamburger-button:hover svg {
    fill: #6622CC;
}

.mobile-header-logo {
    flex-grow: 1;
    display: block;
    object-fit: contain;
    height: 40px;
}

.mobile-header-vortex {
    width: 50px;
    min-width: 50px;
    margin-left: 20px;
}

@media (max-width: 500px) {
    .mobile-header {
        padding-left: 10px;
        padding-right: 10px;
    }

    .hamburger-button {
        margin-right: 10px;
    }

    .mobile-header-vortex {
        margin-left: 5px;
    }
}