.guest {
    background: #284399;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    position: relative;
    overflow: hidden;
    display: flex;
}

.content {
    width: 60%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.name {
    font-size: 40px;
    font-weight: bold;
    text-decoration: underline;
}

.tagline {
    font-size: 20px;
    font-style: italic;
    margin-bottom: 20px;
}

.description {
    font-size: 24px;
    margin-bottom: 20px;
}

.socials {
    display: flex;
}

.social {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    cursor: pointer;
}

.social:hover {
    opacity: 0.75;
}

.social > img {
    display: block;
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.guest > img {
    width: 40%;
    object-fit: contain;
    object-position: bottom;
    background: #fff;
}

@media (max-width: 1600px) {
    .name {
        font-size: 24px;
    }

    .tagline {
        font-size: 14px;
    }

    .description {
        font-size: 18px;
    }
}


@media (max-width: 1075px) {
    .name {
        font-size: 40px;
    }

    .tagline {
        font-size: 20px;
    }

    .description {
        font-size: 24px;
    }
}

@media (max-width: 700px) {
    .name {
        font-size: 24px;
    }

    .tagline {
        font-size: 14px;
    }

    .description {
        font-size: 18px;
    }
}

@media (max-width: 600px) {
    .name {
        font-size: 20px;
    }

    .description {
        font-size: 16px;
    }

    .guest {
        flex-direction: column;
        width: 100%
    }

    .content {
        width: 100%;
        padding-bottom: 10px;
    }
    .guest > img {
        width: 100%;
    }
}