.member-details {
    max-height: 0;
    overflow: hidden;
    transition: 1s all ease;
    width: 100%;
}

.member-details.in {
    max-height: 1000px;
}

.member-details-content-wrapper {
    position: relative;
}
.member-details-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    z-index: 1;
    filter: sepia(100%) saturate(300%) brightness(30%) hue-rotate(210deg) blur(3px);
    opacity: 0.7;
}

.member-details-content {
    position: relative;
    display: flex;
    z-index: 2;
    padding: 20px;
}

.member-details-info-section {
    flex-grow: 1;
    color: #fff;
}

.member-details-name {
    margin-top: 10px;
    font-size: 56px;
    font-weight: bold;
}

.member-details-aliases {
    font-size: 24px;
    font-weight: bold;
}

.member-details-description {
    padding-left: 60px;
    font-size: 20px;
}

.member-details-link-section  {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 40px;
}

.member-details-link-section.grid  {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 40px;
    grid-gap: 0 20px;
    max-height: 50px;
}

@media (max-width: 1000px) {
    .member-details-content {
        flex-direction: column;
    }

    .member-details.in {
        max-height: 2000px;
    }

    .member-details-info-section {
        flex-grow: initial;
    }

    .member-details-link-section {
        margin-top: 20px;
        margin-left: 0;
        max-height: none;
        padding: 0 20px;
    }

    .member-details-link-section.grid  {
        margin-top: 20px;
        margin-left: 0;
        grid-gap: 0 20px;
        max-height: none;
    }
}

@media (max-width: 700px) {
    .member-details-link-section.grid  {
        display: block;
    }
}

.drybones-image {
    position: fixed;
    pointer-events: none;
    opacity: 0;
    transition: opacity 3s ease;
    z-index: 100;
}

.train-video {
    position: fixed;
    pointer-events: none;
    opacity: 0;
    transition: opacity 3s ease;
    z-index: 100;
    width: 400px;
}

.invisible-link {
    color: #fff;
    text-decoration: none;
}