.social-link {
    display: flex;
    text-decoration: none;
    align-items: center;
    color: #29dc7c;
    margin-bottom: 10px;
    filter: sepia(0) saturate(100%) brightness(100%);
    transition: all 300ms ease;
}

.social-link:hover {
    filter: sepia(0) saturate(0) brightness(300%);
}

.social-link img {
    height: 50px;
    width: 50px;
    display: block;
    object-fit: contain;
    margin-right: 10px;
}

.social-link span {
    white-space: nowrap;
    font-size: 24px;
}