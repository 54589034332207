.prototype-logs-page {
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 40px;
}

.prototype-logs-page h1 {
    text-align: center;
    font-weight: bold;
    color: #29DC7C;
    font-size: 48px;
}

.prototype-log {
    background: #000;
    padding: 20px;
    margin-bottom: 40px;
    width: 100%;
}


.prototype-log-title {
    font-size: 36px;
    margin-bottom: 20px;
}

.prototype-log.forbidden {
    color: #f00;
}

.prototype-log-content {
    display: block;
    word-wrap: break-word;
    font-family: monospace;
}