.blog-page {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.blog-page-content {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1500px;
    position: relative;
    overflow: hidden;
}

.blog-main-section {
    flex-grow: 1;
    padding-top: 100px;
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.blog-main-section-content {
    flex-grow: 1;
    max-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.blog-date-header {
    background: #29dc7c;
    padding: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.blog-date-spacer {
    padding: 0 10px;
}

.blog-content {
    background: rgba(0, 0, 0, 0.5);
    padding: 0 30px;
    color: #29dc7c;
    font-size: 24px;
}

.blog-content a {
    color: #B823FF;
    text-decoration: none;
}

.blog-main-section img {
    display: block;
    width: 100%;
    max-height: 600px;
    object-fit: contain;
}

.blog-archive-sidebar {
    width: 400px;
    margin-left: 40px;
    margin-top: 100px;
    flex-shrink: 0;
}

.blog-archive-content {
    background: #B823FF;
    padding: 5px;
    display: flex;
    flex-direction: column;
    max-height: 400px;
}

.blog-archive-header {
    font-weight: bold;
    font-size: 48px;
    color: #fff;
    flex-shrink: 0;
}

.blog-archive-list {
    margin-top: 10px;
    flex: 1;
    overflow: auto;
}

.blog-archive-item {
    display: flex;
    margin-top: 5px;
}

.blog-archive-item:first-child {
    margin-top: 0;
}


.blog-archive-spacer {
    width: 20px;
    background: #272536;
    margin-right: 5px;
    flex-shrink: 0;
}

.blog-archive-list a {
    flex-grow: 1;
    height: 80px;
    background: #272536;
    color: #B823FF;
    transition: all 300ms ease;
    text-decoration: none;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.blog-archive-list a:hover {
    color: #fff;
}

.blog-archive-list a.active,
.blog-archive-list a.active:hover {
    color: #fff;
    background: transparent;
}

.blog-next-back-buttons {
    display: flex;
    background: #B823FF;
    padding: 5px;
    justify-content: space-between;
}

.blog-next-back-button {
    width: calc(50% - 2.5px);
    background: #272536;
    color: #B823FF;
    height: 80px;
    display: flex;
    font-size: 24px;
    align-items: center;
}

.blog-next-back-button a {
    text-decoration: none;
    color: #B823FF;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 10px;
}

.blog-next-back-button a:hover {
    color: #fff;
}

.blog-reverse-triangle {
    transform: rotate(180deg);
    display: inline-block;
}

.blog-next-back-button i {
    font-size: 64px;
}

.blog-empty-button {
    padding: 10px;
}

.blog-open-archive-button {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    background: #B823FF;
    color: #272536;
    font-weight: bold;
    font-size: 36px;
    width: 60px;
    height: 60px;
    border: none;
    cursor: pointer;
}

.blog-open-archive-button * {
    transition: all 300ms ease;
    transform: rotate(0deg);
    display: block;
}

.blog-open-archive-button.open * {
    transform: rotate(180deg);
}


@media (max-width: 1850px) {
    .blog-main-section {
        padding-top: 0;
    }
    .blog-archive-sidebar {
        margin-top: 0;
    }
}

@media (max-width: 1450px) {
    .blog-page {
        display: block;
    }

    .blog-open-archive-button {
        display: block;
    }

    .blog-archive-sidebar {
        position: absolute;
        top: 0;
        left: 100%;
        height: 100%;
        width: 100%;
        margin: 0;
        transition: all 300ms ease;
    }

    .blog-archive-content {
        max-height: 100%;
        height: 100%;
    }

    .blog-archive-list {

    }

    .blog-archive-sidebar.open {
        left: 0;
    }
}

@media (max-width: 1000px) {
    .blog-main-section {
        padding-top: 0;
    }

    .blog-archive-sidebar {
        position: fixed;
    }
}

@media (max-width: 600px) {
    .blog-next-back-buttons {
        display: block;
        padding: 5px;
        justify-content: space-between;
    }

    .blog-next-back-button {
        width: 100%;
    }

    .blog-next-back-button:first-child {
        margin-bottom: 5px;
    }
}