.prototype-page {
    background: #000;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    color: #fff;
    font-family: serif;
}

.prototype-page:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url("../../../../public/prototype/background.gif") repeat;
    opacity: 0.2;
}

.prototype-sidebar-wrapper {
    width: 300px;
    min-width: 300px;
    border-right: 5px double #fff;
    color: #29DC7C;
    position: relative;
    z-index: 2;
}

.prototype-main-content {
    flex-grow: 1;
    position: relative;
    z-index: 2;
    width: calc(100% - 300px);
    height: 100%;
    max-height: 100%;
    overflow: auto;
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.threat {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    animation: fadeout 3s linear;
}