@keyframes animatedBackground {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 100% 100%;
    }
}

.layout {
    height: 100%;
    min-height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    background: url("../../public/landing/grid-background.PNG") repeat;
    animation: animatedBackground 20s linear infinite;
}

.sidebar-wrapper {
    height: 100%;
    min-width: 250px;
    width: 250px;
    transition: all 300ms ease;
}

.sidebar-wrapper.open {
    left: 0;
}

.main-content {
    flex-grow: 1;
}

.mobile-header-wrapper {
    display: none;
}

@media (max-width: 1000px) {
    .layout {
        height: auto;
        flex-direction: column;
    }

    .main-content {

    }

    .sidebar-wrapper {
        position: absolute;
        top: 0;
        left: -250px;
        z-index: 3;
    }

    .mobile-header-wrapper {
        display: block;
    }
}