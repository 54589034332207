.bluescreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0078D7;
    z-index: 100;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.bluescreen-content {
    max-width: 1500px;
}

.bluescreen-smiley {
    font-size: 200px;
}

.bluescreen-big-text {
    font-size: 48px;
    line-height: 80px;
    margin-bottom: 60px;
}

.bluescreen-percentage {
    font-size: 48px;
    margin-bottom: 60px;
}

.bluescreen-qr-and-error {
    display: flex;
    font-size: 28px;
}

.bluescreen-qr-code {
    height: 200px;
    margin-right: 40px;
}

.bluescreen-error {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 1000px) {
    .bluescreen-big-text {
        font-size: 24px;
        line-height: 40px;
    }

    .bluescreen-qr-and-error {
        font-size: 16px;
    }

    .bluescreen-qr-code {
        height: 100px;
        margin-right: 20px;
    }
}

@media (max-width: 400px) {
    .bluescreen-qr-and-error {
        flex-direction: column;
    }

    .bluescreen-qr-code {
        height: auto;
        margin-right: 0;
        margin-bottom: 20px;
    }
}