.page-title {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 60px;
    border-bottom: 1px solid #fff;
    margin-bottom: 60px;
}

.page-title img {
    width: 30%;
    display: block;
    object-fit: contain;
}

.page-title div {
    font-size: 96px;
    font-weight: bold;
    text-align: center;
    width: 70%;
    padding-left: 20px;
}

@media (max-width: 1500px) {
    .page-title  {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width: 1200px) {
    .page-title div {
        font-size: 72px;
    }
}

@media (max-width: 900px) {
    .page-title {
        padding-bottom: 40px;
        margin-bottom: 40px;
    }

    .page-title div {
        font-size: 48px;
    }
}

@media (max-width: 600px) {
    .page-title {
        display: flex;
        flex-direction: column;
    }

    .page-title img {
        width: 100%;
        height: initial;
        max-height: 200px;
        margin-bottom: 20px;
    }

    .page-title div {
        width: 100%;
        padding-left: 0;
    }
}