.prototype-landing-page {
    padding: 100px 50px;
    display: flex;
    justify-content: space-around;
}

.prototype-landing-page-content {
    max-width: 1000px;
    width: 100%;
}

.landing-stream-add {
    font-size: 36px;
    font-family: "Comic Sans MS";
    color: #29DC7C;
}

.main-landing-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
}

.landing-banner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 48px;
}

.landing-gk-cube {
    display: block;
    width: 200px;
}

.landing-footer {
    font-weight: bold;
}

.walking-cat {
    filter: sepia(100%) saturate(4000%) brightness(70%) hue-rotate(190deg);
}

.email-banner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.email-banner div {
    margin: 0 20px;
    text-align: center;
}

.email-banner img {
    filter: sepia(100%) saturate(4000%) brightness(70%) hue-rotate(240deg);
}