.prototype-about-page {
    padding: 100px 50px;
    display: flex;
    justify-content: space-around;
}

.prototype-about-page-content {
    max-width: 1000px;
    width: 100%;
}

.about-banner {
    width: 100%;
}

.prototype-about-title {
    font-family: "Comic Sans MS";
    color: #29DC7C;
    font-size: 48px;
    text-align: center;
}

.prototype-about-subtitle {
    font-family: "Comic Sans MS";
    color: #29DC7C;
    font-size: 36px;
    text-align: center;
    margin: 20px 0;
}

.prototype-about-page p {
    font-size: 24px;
}

.prototype-about-divider {
    width: 100%;
    filter: invert();
}

.castruction {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}

.prototype-about-friends {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
}

.prototype-about-friends * {
    width: 88px;
    height: 31px;
}