.landing-page {
    height: 100%;
    width: 100%;
    overflow: none;
    display: flex;
    padding-top: 10%;
    justify-content: center;
}

.main-logo {
    width: 40%;
}

.main-logo-content {
    position: relative;
}

@keyframes rotate-logo {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.main-vortex {
    position: relative;
    z-index: 1;
    transform-origin: 50% 50%;
    animation: rotate-logo 40s linear infinite;
}

.main-logo-text {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    transform: translateY(-50%);
}

@media (max-width: 1000px) {
    .main-logo {
        width: 80%;
    }
}