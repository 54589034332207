.spark-image {
    position: fixed;
    pointer-events: none;
    opacity: 0;
    transition: opacity 3s ease;
    z-index: 100;
}

.raging-storm {
    position: fixed;
    pointer-events: none;
    opacity: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 300px;
}