.team-member {
    width: 210px;
    position: relative;
    background: transparent;
    padding: 0;
    border: 0;
    margin: 0 20px 20px 20px;
    align-items: center;
    cursor: pointer;

}

.team-member:hover img {
    filter: sepia(100%) saturate(100%) brightness(70%) hue-rotate(90deg);
}

.team-member.selected:hover img {
    filter: sepia(100%) saturate(100%) brightness(70%) hue-rotate(270deg);
}

.team-member.selected::before {
    content: '';
    border: 5px dashed #29DC7C;
    border-radius: 5px;
    position: absolute;
    top: -5px;
    left: -5px;
    bottom: -5px;
    right: -5px;
    z-index: 2;
}

.team-member img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: all 300ms ease;
    filter: sepia(0) saturate(100%) brightness(100%) hue-rotate(0);
}

.live-notification  {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 10px;
    background: #612486;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
}

.live-notification.live {
    background: #C00202;
}

@media (max-width: 1000px) {
    .team-member {
        width: 33.333333%;
        margin: 0;
    }
}

@media (max-width: 500px) {
    .team-member {
        width: 50%;
    }
}

@media (max-width: 300px) {
    .team-member {
        width: 100%;
    }
}