.spinny-vortex {
    width: 100%;
    padding: 40px;
    transition: all 1s ease;
    overflow: hidden;
}
.spinny-vortex:hover {
    padding: 20px;
}

@keyframes rotate-logo {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.spinny-vortex svg {
    fill: #B823FF;
    transform-origin: 50% 50%;
    animation: rotate-logo 40s linear infinite;
}

.spinny-vortex.fast svg {
    animation: rotate-logo 1s linear infinite;
}


.spinny-vortex.mobile svg {
    fill: #6622CC;
}

.spinny-vortex.mobile {
    fill: #B823FF;
    padding: 5px;
}

.spinny-vortex.mobile:hover {
    padding: 0;
}