.guests-page {
    width: 100%;
    max-width: 1500px;
}

.guests-list {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(740px, 1fr));
    margin-bottom: 60px;
}

@media (max-width: 1600px) {
    .guests-page {
        padding: 0 20px;
    }

    .guests-list {
        grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    }
}

@media (max-width: 600px) {
    .guests-list {
        display: flex;
        flex-direction: column;
    }
}