.team-page {
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    padding-top: 100px;
    position: relative;
}

.team-content {
    width: 100%;
    max-width: 1500px;
}

@media (max-width: 1850px) {
    .team-page {
        padding-top: 0;
    }
}

.team-toasty {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 300px;
    width: 300px;
    pointer-events: none;
}