@font-face { font-family: BebasPro; src: url('../assets/Fontspring-DEMO-bebasneuepro-middle.otf'); }

@keyframes p1InAnimation {
    from {
        opacity: 0;
        left: -100px;
    }
    to {
        opacity: 1;
        left: 50px;
    }
}

@keyframes p2InAnimation {
    from {
        opacity: 0;
        right: -100px;
    }
    to {
        opacity: 1;
        right: 50px;
    }
}

.charachter-info {
    position: absolute;
    max-width: 500px;
}

.charachter-info.player1 {
    top: 500px;
    left: 50px;
    animation-name: p1InAnimation;
    animation-duration: 1s;
}

.charachter-info.player2 {
    top: 500px;
    right: 50px;
    text-align: right;
    animation-name: p2InAnimation;
    animation-duration: 1s;
}

.charachter-name {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: italic;
    color: #E1E4F3;
    font-size: 100px;
    text-shadow: 0 0 4px #354de5;
}

.charachter-details {
    font-family: "Bebas Neue", sans-serif;
}

.details-item {
    margin-bottom: 10px;
}

.details-header {
    padding: 0 10px;
    color: #000;
    background: #EB366F;
    font-size: 20px;
    display: inline-block;
}

.charachter-info.player2 .details-header {
    background: #308DEC;
}

.details-content {
    color: #fff;
    font-size: 28px;
    font-family: BebasPro;
    margin-left: 10px;
}

.charachter-info.player2 .details-content {
    margin-left: 0;
    margin-right: 10px;
}