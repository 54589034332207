.landing-page {
    width: 100%;
    max-width: 1500px;
    padding-bottom: 30px;
}

.welcome {
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome img {
    width: 100px;
}

.welcome-text {
    font-size: 72px;
    font-weight: bold;
    padding: 0 20px;
}

.big-logo {
    margin: 0 auto;
    display: block;
    height: 400px;
}

.divider {
    height: 1px;
    background: #fff;
    margin: 80px 0;
}

.text-blurbs {
}

.text-blurb {
    margin-bottom: 40px;
    display: flex;
    font-size: 48px;
}

.text-blurb.right {
    flex-direction: row-reverse;
}

.text-blurb.center {
    text-align: center;
    padding: 0 40px;
}

.text-blurb img {
    height: 200px;
    display: block;
    margin-right: 40px;
}

.text-blurb.right img {
    margin-right: 0;
    margin-left: 40px;
}

.text-blurb div {

}

.text-blurb a {
    color: #4c56a7;
    text-decoration: none;
    cursor: pointer;
}

.ending {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ending img {
    height: 200px;
    display: block;
    object-fit: contain;
}

.ending div {
    font-size: 72px;
    font-weight: bold;
    text-align: center;
    width: 700px;
}

.associated {
    margin-top: 100px;
}

.associated-title {
    text-align: center;
    font-style: italic;
    font-size: 48px;
    margin-bottom: 20px;
}

.associated-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.associated-links a {
    margin-right: 30px;
}

.associated-links img {
    height: 200px;
}

@media (max-width: 1500px) {
    .landing-page {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
    }
}

@media (max-width: 1100px) {
    .welcome-text {
        font-size: 54px;
    }

    .welcome img {
        width: 60px;
    }

    .big-logo {
        height: 250px;
    }

    .divider {
        margin: 40px 0;
    }

    .text-blurb {
        margin-bottom: 20px;
        font-size: 36px;
    }

    .text-blurb img {
        height: 150px;
        margin-right: 20px;
    }

    .ending div {
        font-size: 48px;
        width: auto;
    }

    .ending {
        padding: 0 40px;
    }
}

@media (max-width: 700px) {
    .welcome-text {
        font-size: 36px;
    }

    .welcome img {
        width: 30px;
    }

    .big-logo {
        height: 150px;
    }

    .associated-links img {
        height: 100px;
        margin-bottom: 10px;
    }
}

@media (max-width: 600px) {
    .welcome-text {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .welcome img {
        display: none;
    }

    .big-logo {
        height: initial;
        width: 100%;
    }

    .text-blurb {
        flex-direction: column-reverse;
        font-size: 20px;
    }

    .text-blurb.right {
        flex-direction: column-reverse;
    }

    .text-blurb img {
        height: 100px;
        width: 100%;
        object-fit: contain;
        display: block;
        margin-right: 0;
        margin-top: 20px;
    }

    .text-blurb.right img {
        margin-left: 0;
    }
    .text-blurb.center {
        text-align: center;
        padding: 0;
    }

    .ending img {
        display: none;
    }

    .ending div {
        font-size: 36px;
    }

    .ending {
        padding: 0;
    }

    .associated {
        margin-top: 40px;
    }

    .associated-title {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .associated-links {
        flex-direction: column;
    }

    .associated-links a {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .associated-links img {
        height: initial;
        width: 100%;
        padding: 0 20px;
    }
}