.about-page {
    height: 100%;
    max-height: 100%;
    overflow: auto;
    width: 100%;
    display: flex;
    justify-content: space-around;
    position: relative;
}

.about-page-easter-egg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    pointer-events: none;
}

.about-page-easter-egg video {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: fill;
}

.about-page-content {
    max-width: 1500px;
    width: 100%;
    padding-top: 100px;
    position: relative;
    z-index: 2;

}

@media (max-width: 1500px) {
    .about-page-content {
        max-width: 1000px;
    }
}


@media (max-width: 1850px) {
    .about-page-content {
        padding-top: 0;
    }
}

.about-page-inner-content {
    background: rgba(0, 0, 0, 0.5);
    padding: 30px;
}

.about-blurb-and-image {
    display: flex;
}

.about-page-team-image-and-credits {
    display: flex;
    flex-direction: column;
    width: 50%;
    min-width: 50%;
    margin-left: 20px;
}

.about-page-team-image-credits {
    margin-top: 10px;
    color: #fff;
    text-decoration: none;
    font-size: 36px;
}

.about-page-team-image {
    display: block;
    width: 100%;
    object-fit: contain;
    object-position: top;
}

@media (max-width: 800px) {
    .about-blurb-and-image {
        flex-direction: column;
    }

    .about-page-team-image-and-credits {
        max-width: 100%;
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
    }
}

.about-page-header {
    font-size: 56px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 20px;
}

.about-page-section {
    margin-top: 30px;
}

.retired-members {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
}

.retired-member {
    display: flex;
    gap: 10px;
}

.retired-member > img {
    height: 130px;
    width: 130px;
}

.retired-member > div {
    padding: 10px 0;
}

.retired-member-name {
    font-size: 36px;
    color: #fff;
}

.retired-member-socials {
    display: flex;
    gap: 10px;
}

@media (max-width: 1600px) {
    .retired-members {
        grid-template-columns: 1fr;
    }
}

.about-page-blurb {
    color: #29dc7c;
    font-size: 24px;
}

.about-page-credits {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media (max-width: 600px) {
    .about-page-header {
        font-size: 36px;
    }

    .about-page-credits {
        flex-direction: column;
    }

    .about-page-credit {
        margin-bottom: 20px;
    }
}


.about-page-credit {
    text-align: center;
}

.about-page-credit-name {
    color: #fff;
    font-size: 36px;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;
}

.about-page-credit-thing {
    font-style: italic;
    color: #29dc7c;
    font-size: 24px;
}

.about-page-scrimblo {
    margin-top: 40px;
    text-align: center;
    position: relative;
    height: 128px;
}

.about-page-scrimblo img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.about-page-scrimblo img.after-image-1 {
    filter: saturate(0) brightness(10000%) sepia(100%) saturate(1000%) hue-rotate(210deg);
    opacity: 0.5;
}

.about-page-scrimblo img.after-image-2 {
    filter: saturate(0) brightness(10000%) sepia(100%) saturate(1000%) hue-rotate(210deg) brightness(75%);
    opacity: 0.5;
}

@keyframes moveScrimblo {
    0% {
        left: 50%;
    }
    33% {
        left: 100%;
    }
    66% {
        left: 0;
    }
    100% {
        left: 50%;
    }
}

.about-page-scrimblo img.animate {
    animation: moveScrimblo 3s ease;
}

.about-page-scrimblo img.after-image-1.animate {
    animation-delay: 0.05s;
}

.about-page-scrimblo img.after-image-2.animate {
    animation-delay: 0.1s;
}

.chilean-flag {
    position: fixed;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    transition: all 5s ease;
}

.chilean-flag.up {
    transform: translateY(0);
}