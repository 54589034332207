.game {
    display: flex;
    margin-bottom: 60px;
}

.game.flipped {
    flex-direction: row-reverse;
}

.game.flipped .content {
    margin-right: 0;
    margin-left: 60px;
    flex-direction: row-reverse;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
}

.game.flipped .content > img {
    right: 0;
    left: auto;
}

.content {
    background: #284399;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    display: flex;
    width: 60%;
    margin-right: 60px;
    position: relative;
    overflow: hidden;
    padding: 20px 20px 20px 20px;
    justify-content: flex-end;
}

.content > img {
    display: block;
    object-fit: contain;
    position: absolute;
    right: 60%;
    top: 0;
    z-index: 2;
    height: 100%;
}

.logo-and-text {
    width: 60%;
    padding-left: 20px;
    position: relative;
}

.logo-and-text img {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.25;
}

.logo-and-text div {
    font-size: 36px;
    position: relative;
    z-index: 3;
}

.game > img {
    width: 40%;
    display: block;
    object-fit: contain;
    object-position: top;
}

@media (max-width: 1200px) {
    .game,
    .game.flipped {
        flex-direction: column;
    }

    .game.flipped .content {
        margin-left: 0;
    }

    .content {
        width: 100%;
        margin-right: 0;
        margin-bottom: 60px;
    }

    .game > img {
        width: 100%;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        overflow: hidden;
    }

    .game.flipped > img {
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
    }
}

@media (max-width: 700px) {
    .logo-and-text div {
        font-size: 24px;
    }

    .game,
    .content {
        margin-bottom: 40px;
    }

}

@media (max-width: 500px) {
    .logo-and-text div {
        font-size: 20px;
    }

    .content > img {
        display: none;
    }

    .logo-and-text {
        width: 100%;
        padding-left: 0;
    }

    .content {
        padding: 30px;
    }
}