.sidebar {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #B823FF 0%, #6622CC 100%);
    padding: 0 5px;
    position: relative;
    justify-content: space-between;
}

.sidebar::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 5px;
    background: #B823FF;
}

.close-button-section {
    display: none;
    height: 150px;
}

.nav-title,
.nav-item {
    height: 75px;
    font-size: 36px;
    font-weight: 800;
    display: flex;
    margin-bottom: 5px;
    line-height: 75px;
    color: #B823FF;
    justify-content: space-between;
}

.nav-title {
    height: 100px;
}

.nav-title-name {
    padding: 20px;
}

.nav-title-name img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
}

.spacer {
    background: #272536;
    width: 30px;
    min-width: 30px;
    margin-right: 5px;
}

.spacer.hidden {
    opacity: 0;
}

.nav-title-name,
.nav-item-name {
    flex: 1;
}

.nav-item-name {
    background: #272536;
    padding: 0 20px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    color: #B823FF;
    text-transform: uppercase;
    transition: all 300ms ease;
}

.nav-item-name:hover {
    color: #fff;
}

.nav-item-name::after {
    content: '';
    background: #272536;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    height: 5px;
}
.nav-item:last-child .nav-item-name::after {
    display: none;
}

.nav-item-name.active {
    color: #fff;
}

.nav-item-name.active,
.nav-item-name.active::after {
    background: transparent;
}

.close-sidebar-button-wrapper {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 10px;
}

.close-sidebar-button {
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
    height: 35px;
    width: 35px;
}

.close-sidebar-button svg{
    fill: #fff;
    transition: all 300ms ease;
}

.close-sidebar-button:hover svg {
    fill: #6622CC;
}

.nav-audios {
    height: 0;
    width: 0;
    display: none;
}

@media (max-width: 1000px) {
    .nav-logo {
        display: none;
    }

    .nav-title {
        display: none;
    }

    .close-button-section {
        display: flex;
    }
}