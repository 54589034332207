.video {
    width: 50%;
    position: relative;
    display: block;
}

.video:hover .video-background {
    filter: sepia(100%) saturate(100%) brightness(70%) hue-rotate(90deg);
}

.video.big {
    width: 100%;
}

.video-background {
    width: 100%;
    display: block;
    filter: sepia(0) saturate(100%) brightness(100%) hue-rotate(0);
    transition: all 300ms ease;
}

.video-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background: #00000088;
    color: #fff;
    font-size: 16px;
    text-underline-style: none;
}

@media (max-width: 500px) {
    .video {
        width: 100%;
    }
}