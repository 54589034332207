*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body, #root {
  margin: 0;
  padding: 0;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  width: 100%;
  height: 100%;
}
