.event {
    background: #000;
    overflow: hidden;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    margin-bottom: 60px;
}

.title-and-host {
    display: flex;
    margin-bottom: 20px;
}

.title-section {
    width: 60%;
    margin-right: 20px;
    background: #284399;
    padding: 20px;
}

.title {
    text-decoration: underline;
    font-size: 72px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
}

.title-description {
    font-size: 48px;
}

.host-section {
    background: #284399;
    width: 40%;
    padding: 20px;
    position: relative;
    overflow: hidden;
}

.host {
    font-size: 72px;
    width: 50%;
    position: relative;
    z-index: 2;
}

.host-section img {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    max-width: 100%;
}

.description-section {
    background: #284399;
    padding: 20px;
    display: flex;
}

.description-section img {
    display: block;
    width: 200px;
    object-fit: contain;
}

.description {
    font-size: 36px;
    padding-left: 60px;
}

@media (max-width: 1200px) {
    .title-and-host {
        margin-bottom: 10px;
    }

    .title-section {
        margin-right: 10px;
    }

    .title {
        font-size: 64px;
    }

    .title-description {
        font-size: 36px;
    }

    .host {
        font-size: 48px;
    }

    .description-section img {
        width: 150px;
    }

    .description {
        font-size: 32px;
    }
}

@media (max-width: 1100px) {
    .title-and-host {
        flex-direction: column;
    }

    .title-section {
        margin-right: 0;
        border-bottom: 10px solid #000;
        width: 100%;
    }

    .host-section {
        width: 100%;
    }

    .host-section img {
        max-width: initial;
        height: 100%;
    }

    .description {
        padding-left: 20px;
    }

    .description-section img {
        width: 200px;
    }
}

@media (max-width: 800px) {
    .description-section {
        flex-direction: column;
    }

    .description {
        padding-left: 0;
    }

    .description-section img {
        width: 100%;
        margin-bottom: 20px;
    }
}

@media (max-width: 600px) {
    .title-and-host {
        margin-bottom: 5px;
    }

    .title-section {
        border-bottom: 5px solid #000;
    }

    .title {
        font-size: 36px;
    }

    .title-description {
        font-size: 24px;
    }

    .host {
        font-size: 36px;
    }

    .description-section img {
        width: 100%;
        max-height: 100px;
    }

    .description {
        font-size: 16px;
    }
}