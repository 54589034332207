.games-page {
    width: 100%;
    max-width: 1500px;
}

.games-list {
}

.more-games {
    margin-top: 60px;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    background: #284399;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.more-games div {
    padding: 20px 100px;
    font-size: 72px;
    font-weight: bold;
    text-align: center;
}

.more-games img {

}

@media (max-width: 1500px) {
    .games-page  {
        padding: 0 20px;
    }
}

@media (max-width: 1000px) {
    .more-games div {
        padding: 20px 60px;
        font-size: 48px;
    }
}

@media (max-width: 600px) {
    .more-games div {
        padding: 20px 40px;
        font-size: 36px;
    }
}

@media (max-width: 500px) {
    .more-games div {
        padding: 20px;
        font-size: 24px;
    }
}